a {
  color: #1a63a3;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.topbar {
  height: 25px;
  width: 100vw;
  background-color: #333333;
}
.header-bg {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
  width: 100vw;
}
.header {
  width: 920px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333333;
}
.header-btn {
  margin: auto;
  padding: 24.5px 13px 24.5px 13px;
}
.header-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #1a63a3;
  cursor: pointer;
}
.breadcrumbs {
  width: 960px;
  padding-bottom: 10px;
}
.level {
  margin-left: 15px;
  display: flex;
  font-size: 11px;
  color: #414042;
  padding-top: 10px;
}
.breadcrumb-btn:hover {
  color: #1a63a3;
  text-decoration: underline;
  cursor: pointer;
}
.breadcrumb-btn:focus {
  color: #1a63a3;
}
.main-body {
  width: 960px;
  display: flex;
  margin-bottom: 110px;
}
.stories {
  width: 700px;
  margin-right: 15px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #414042;
  margin-bottom: 10px;
}
.explain {
  font-size: 16px;
  color: #6d6e71;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px dashed #808285;
}
.order {
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-right: 1px solid #808285;
  font-size: 12px;
}
.summary {
  margin-top: 10px;
  padding: 10px;
}
.summary-title {
  font-size: 18px;
  color: #5b8fb5;
  font-weight: bold;
}
.summary-content {
  display: flex;
  justify-content: space-between;
}
.summary-content-left {
  width: 440px;
  padding-right: 15px;
}
.detail {
  font-size: 12px;
  display: flex;
  color: #414042;
  line-height: 17px;
}
.feedbackicon {
  width: 5px;
  height: 5px;
  background-color: rgb(165, 216, 165);
  border: 0.1px solid black;
  border-radius: 1px;
  rotate: 45deg;
}
.comment-text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #414042;
}
.comment-author {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #414042;
}
.comment-underline {
  height: 30px;
  border-bottom: 1px solid #414042;
}
.gutterside {
  width: 215px;
  margin-left: 15px;
  margin-right: 15px;
}
.vehicles {
  background-color: #972224;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #fff;
}
.input1 {
  width: 58px;
  height: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 11px;
}
.input2 {
  width: 100%;
  height: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 11px;
}
.more-title {
  background-color: #972224;
  border-radius: 4px;
  padding: 5px 0 5px 10px;
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
.more {
  padding: 8px 0 15px 15px;
  border-bottom: 2px solid #e3e4e5;
}
.footer-bg {
  background-color: #fcfcfc;
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.footer {
  width: 960px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
}
.footer-category {
  font-weight: bold;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 5px;
}
.footer-content {
  font-size: 12px;
  padding-top: 7px;
}
.footer-right {
  display: flex;
}
.order1 {
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-right: 1px solid #808285;
  font-size: 11px;
  color: #888;
  font-weight: bold;
}
.downfooter {
  width: 960px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

@media screen and (max-width: 890px) {
  .footer-right {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}
